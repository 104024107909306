import React from "react"
import ColorshelfPage from "../../components/colorlist/colorshelfpage"

const colorlist = require('../../content/custom/colorlist/shelf4.json');


const ColorlibraryShelf4Page = () => (
	<ColorshelfPage
			activePath={"colorlibrary/shelf4"}
			colorList={colorlist}
		/>
)

export default ColorlibraryShelf4Page
